import { faCircleUser, faUser, faUserCircle, faUserCog, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addSpecialRewardAPI, getUserCommunitiesAPI } from "../../api/rewards";
import { isAdminUser, isNumber, IUserCommunityResultItem } from "../../utils";
import { useAccounts } from "../accounts/context";
import { searchUserAPI } from "../../api/users";
import { IUserSearchResultType } from "../../utils";

const IncentivizeUser = () => {

    const {
        state: {user, curEnv}
    } = useAccounts();

    const [showModal, setShowModal] = useState(false);  
    const [userResults, setUserResults] = useState<Array<IUserSearchResultType>>([]);
    const [currentUser, setCurrentUser] = useState<IUserSearchResultType>({} as IUserSearchResultType);
    // const [communityResults, setCommunityResults] = useState<Array<IUserCommunityResultItem>>([]);
    const [changeCount, setChangeCount] = useState(0);
    // const [currentCommunityResult, setCurrentCommunityResult] = useState<IUserCommunityResultItem>({} as IUserCommunityResultItem);
    const [rewardAmount, setRewardAmount] = useState(0);    
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        isAdminUser()
            .then(result => {
                if (result) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
            }).catch(err => {
                setIsAdmin(false);
                console.log(err);                
            });
    }, []);        

    const handleSearchQueryChange = (e: any) => {
        e.preventDefault();
        if (e.target.value === '') {
            setUserResults([]);
        } else if (isNumber(e.target.value)) {
            searchUserAPI(
                curEnv, 
                e.target.value
            ).then(data => {
                console.log("User Results: ", data);
                if (Array.isArray(data) && data.length > 0) {
                    data.forEach((item: any) => {
                        const tempObj = {} as IUserSearchResultType;
                        tempObj.userId = item["userid"];
                        tempObj.userName = item["user_name"];
                        tempObj.userHandle = item["userhandle"];
                        tempObj.email = item["email"];
                        tempObj.joinDate = item["created"];

                        setUserResults(oldData => [...oldData, tempObj]);
                    });
                }
            }).catch(err => {
                console.log(err);
            });

            // getUserCommunitiesAPI(curEnv, userId, userHandle)
            //     .then(data => {
            //         console.log(data);
            //         setCommunityResults([]);
            //         data.forEach((item: any) => {
            //             if (item["communityid"] !== null && item["communityname"] !== null) {
            //                 const tempObj = {} as IUserCommunityResultItem;
            //                 tempObj.userId = item["userid"];
            //                 tempObj.name = item["name"];
            //                 tempObj.userHandle = item["userhandle"];
            //                 tempObj.userImage = item["mediumimage"];

            //                 tempObj.communityId = item["communityid"];
            //                 tempObj.communityName = item["communityname"];
            //                 tempObj.communityImage = item["communityimg"];
            //                 tempObj.communityCreatedDate = item["created"];
            //                 tempObj.communityMemberCount = item["member_count"];
            //                 setCommunityResults(oldData => [...oldData, tempObj]);                                
            //             }
            //         });
            //     }).catch(err => {
            //         console.log(err);
            //     });
        } else {
            if (changeCount === 3) {
                setChangeCount(0);
                const userHandle = e.target.value;
                searchUserAPI(
                    curEnv, 
                    userHandle
                ).then(data => {
                    console.log("User Results: ", data);
                    if (Array.isArray(data) && data.length > 0) {
                        data.forEach((item: any) => {
                            const tempObj = {} as IUserSearchResultType;
                            tempObj.userId = item["userid"];
                            tempObj.userName = item["user_name"];
                            tempObj.userHandle = item["userhandle"];
                            tempObj.email = item["email"];
                            tempObj.joinDate = item["created"];

                            setUserResults(oldData => [...oldData, tempObj]);
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
                // getUserCommunitiesAPI(curEnv, userId, userHandle)
                //     .then(data => {
                //         console.log(data);
                //         setCommunityResults([]);
                //         data.forEach((item: any) => {
                //             if (item["communityid"] !== null && item["communityname"] !== null) {
                //                 const tempObj = {} as IUserCommunityResultItem;
                //                 tempObj.userId = item["userid"];
                //                 tempObj.name = item["name"];
                //                 tempObj.userHandle = item["userhandle"];
                //                 tempObj.userImage = item["mediumimage"];

                //                 tempObj.communityId = item["communityid"];
                //                 tempObj.communityName = item["communityname"];
                //                 tempObj.communityImage = item["communityimg"];
                //                 tempObj.communityCreatedDate = item["created"];
                //                 tempObj.communityMemberCount = item["member_count"];

                //                 setCommunityResults(oldData => [...oldData, tempObj]);                                
                //             }
                //         });
                //     }).catch(err => {
                //         console.log(err);
                //     });
            } else {
                setChangeCount(changeCount + 1);
            }
        }
    }

    const handleResultClick = (e: any, userItem: IUserSearchResultType) => {
        e.preventDefault();
        setCurrentUser(userItem);
        setShowModal(true);
        // if (isAdmin) {            
        //     setCurrentCommunityResult(communityItem);
        //     setShowModal(true);
        // } else {            
        //     toast.error("You need to be admin to incentivize the user");
        // }
        
    }

    const handleModalClose = () => {
        setShowModal(false);
        cleanUp();
    }

    const addSpecialReward = (e: any) => {
        e.preventDefault();
        if (isAdmin) {
            if (rewardAmount > 0) {
                addSpecialRewardAPI(
                    curEnv,                     
                    currentUser.userId,
                    user?.email !== undefined ? user?.email : "feedback@investmates.io",
                    rewardAmount                    
                ).then(data => {
                    toast.success(`Rewarded ${rewardAmount} to ${currentUser.userName}`);
                    cleanUp();
                    setShowModal(false);
                }).catch(err => {
                    console.log(err);
                    toast.error("Error giving reward");
                    cleanUp();
                    setShowModal(false);
                });
                // addSpecialRewardAPI(
                //     curEnv, 
                //     currentCommunityResult.communityId,
                //     currentCommunityResult.userId,
                //     user?.email !== undefined ? user?.email : "feedback@investmates.io",
                //     rewardAmount                    
                // ).then(data => {
                //     toast.success(`Rewarded ${rewardAmount} to ${currentCommunityResult.name}`);
                //     cleanUp();
                //     setShowModal(false);
                // }).catch(err => {
                //     console.log(err);
                //     toast.error("Error giving reward");
                //     cleanUp();
                //     setShowModal(false);
                // });
            } else {                
                toast.error("Please enter a valid reward amount");
            }            
        } else {
            toast.error("You need to be admin to incentivize user");
        }        
    }

    const cleanUp = () => {
        setRewardAmount(0);
        // setCurrentCommunityResult({} as IUserCommunityResultItem);        
        setCurrentUser({} as IUserSearchResultType);
    }

    const fillTable = () => {
        return userResults.map((userItem, index) => {
            return (                
            <tr key={index}>
                <td className="px-2 py-2 text-center">
                    {userItem.userId}
                </td>
                <td className="px-2 py-2 text-center">
                    <td>
                        {
                            (userItem.profilePicture !== undefined && userItem.profilePicture !== "" && userItem.profilePicture !== null) 
                            ? <img
                                className="w-10 h-10 rounded-full"                        
                                src={userItem.profilePicture} 
                              />
                            : <FontAwesomeIcon className="w-10 h-10 rounded-full" icon={faUser} />
                        }                        
                    </td>
                    <td className="pl-2">
                        <a 
                            href="#" 
                            onClick={(e) => handleResultClick(e, userItem)}
                            className="text-indigo-600 hover:text-indigo-900 hover:underline" 
                        >
                            {userItem.userName}
                        </a>
                    </td>
                </td>
                <td className="px-2 py-2 text-center">
                    {userItem.userHandle}
                </td>
                <td className="px-2 py-2 text-center">
                    {userItem.email}
                </td>                                
                <td className="px-2 py-2 text-center">
                    {userItem.joinDate}
                </td>
            </tr>)
        });
    }

    return (
        <div className="ml-5 mt-2">
            <input 
                    className="w-96 rounded-full"
                    type="text"
                    onChange={e => handleSearchQueryChange(e)}
                    placeholder="Search by username / User Id" 
                    // value={filter}
                />            
            <div className="mt-4">
                <table>
                    <thead>
                        <tr className="bg-[#F3F3F3]">
                            <th className="p-4">User Id</th>
                            <th className="p-4">User</th>
                            <th className="p-4">User Handle</th>
                            <th className="p-4">Email</th>
                            <th className="p-4">Joined On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fillTable()
                        }
                    </tbody>                    
                </table>
            </div>

            <Modal
                show={showModal}
                size="xl"
                popup={true}
                onClose={() => handleModalClose()}
                >
                    <Modal.Header                        
                        style={{
                            backgroundColor: "#f3f3f3",
                            textAlign: "center"
                        }}
                    >
                        Incentivize User                        
                    </Modal.Header>
                    <Modal.Body> 
                        <div className="flex flex-col w-full mt-2">
                            <div className="flex flex-row">
                                <div className="basis-1/2 flex-col">
                                    <div><b>User Id</b></div>
                                    <div>{currentUser.userId}</div>
                                </div>
                                <div className="basis-1/2 flex-col">
                                    <div><b>User Name</b></div>
                                    <div>{currentUser.userName}</div>
                                </div>
                            </div>
                            <div className="flex flex-row mt-2">
                                <div className="basis-1/2 flex-col">
                                    <div><b>User Handle</b></div>
                                    <div>{currentUser.userHandle}</div>
                                </div>
                                <div className="basis-1/2 flex-col">
                                    <div><b>Email</b></div>
                                    <div>{currentUser.email}</div>
                                </div>
                            </div>
                            <div className="mt-4 flex flex-col">
                                <div className="flex flex-row">
                                    <input 
                                        className="mr-2 rounded-full" 
                                        type="number" 
                                        placeholder="Enter reward coins"
                                        value={rewardAmount > 0 ? rewardAmount : "Enter reward Coins"}
                                        onChange={e => setRewardAmount(Number(e.target.value))}
                                    />
                                    <div className="flex flex-row">
                                        <button 
                                            className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                            onClick={e => addSpecialReward(e)}
                                        >
                                            Incentivize
                                        </button>
                                        <button className="mr-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" 
                                            onClick={() => handleModalClose()}>
                                                Cancel
                                        </button>
                                    </div>                                                                                                                                                                                                                
                                </div>                                                               
                            </div>
                        </div>
                    </Modal.Body>
            </Modal>
        </div>
    );
}

export default IncentivizeUser;