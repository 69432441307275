import { TextInput } from "flowbite-react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { createCampaignAPI, getUserDetailsByEmailAPI } from "../../api/rewards";
import { useAccounts } from "../../components/accounts/context";
import { EqualWidthContainer } from "../dashboard/community/all";
import { PageContainer } from "../dashboard/feedback";
import ErrorBoundary from "../ErrorBoundary";

const CreateCampaign = () => {

    const [userId, setUserId] = useState(0);
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignType, setCampaignType] = useState(1);
    const [rewardCoins, setRewardCoins] = useState(0);
    const [status, setStatus] = useState(0);
    const [expiryDate, setExpiryDate] = useState('');
    const [refreeCoin, setRefreeCoin] = useState(0);
    const [referralCoin, setReferralCoin] = useState(0);
    const [inProgress, setInProgress] = useState(false);
    const [creatorId, setCreatorId] = useState(0);
    const [creatorEmail, setCreatorEmail] = useState("");
    const [creatorName, setCreatorName] = useState('');
    const [creatorHandle, setCreatorHandle] = useState("");    

    // validation state
    const [userIdError, setUserIdError] = useState(false);
    const [campaignNameError, setCampaignNameError] = useState(false);
    const [campaignDescriptionError, setCampaignDescriptionError] = useState(false);    
    const [rewardCoinsError, setRewardCoinsError] = useState(false);
    const [expiryDateError, setExpiryDateError] = useState(false);

    const {
        state: { curEnv, user }
    } = useAccounts();

    useEffect(() => {        
        const getUserData = async () => {            
            const userDetails = await getUserDetailsByEmailAPI(curEnv, user?.email.toString());
            setCreatorId(userDetails[0]["userid"]);
            setUserId(userDetails[0]["userid"]);
            setCreatorEmail(userDetails[0]["email"]);
            setCreatorHandle(userDetails[0]["userhandle"]);
            setCreatorName(userDetails[0]["firstname"] + " " + userDetails[0]["lastname"]);            
        }
        getUserData();
    }, [curEnv]);
    

    const createCampaign = (e: any) => {
        e.preventDefault();
        // TODO: Date and other validation
        if (isValid()) {
            setInProgress(true);
            clearErrors();
            console.log("campaign created");
            console.log("Campaign Name: ", campaignName);
            console.log("Campaign Description: ", campaignDescription);
            console.log("Campaign Type: ", campaignType);
            console.log("rewardCoins: ", rewardCoins);
            console.log("status: ", status);
            console.log("end date: ", expiryDate);
            console.log("user Id: ", userId);
            console.log("refreeCoin: ", refreeCoin);
            console.log("referralCoin: ", referralCoin);
            
            createCampaignAPI(
                curEnv,
                campaignName,
                campaignDescription,
                "",
                campaignType,
                (refreeCoin + referralCoin),
                status,
                expiryDate,
                userId,
                refreeCoin,
                referralCoin
            )
                .then((data: any) => {
                    console.log("Campaign created");                    
                    toast.success("Campaign Created");
                    reset();              
                })
                .catch((err: any) => {
                    console.log("error creating campaign");
                    console.log(err);
                    toast.error("error creating campaign");
                })
                .finally(() => {
                    setInProgress(false);
                })
        } else {
            toast.error("enter all the required fields");
            setErrors();
        }       
    }

    function reset() {
        setUserId(0);
        setCampaignType(1);        
        setCampaignName("");
        setCampaignDescription("");
        setRefreeCoin(0);
        setReferralCoin(0);
        setRewardCoins(0);
        setExpiryDate("");
    }

    function isValid() {
        if (userId === 0 || userId < 0) {
            return false;
        } else if (campaignName === "") {
            return false;
        } else if (campaignDescription === "") {
            return false;
        } else if (refreeCoin === 0 || refreeCoin < 0) {
            return false;
        } else if (referralCoin === 0 || referralCoin < 0) {
            return false;
        } else if (expiryDate === "") {
            return false;
        } else {
            return true;
        }
    }

    const setErrors = () => {
        (userId === 0 || userId < 0) ? setUserIdError(true) : setUserIdError(false);
        (campaignName === "") ? setCampaignNameError(true) : setCampaignNameError(false);
        (campaignDescription === "") ? setCampaignDescriptionError(true) : setCampaignDescriptionError(false);        
        // (rewardCoins === 0 || rewardCoins < 0) ? setRewardCoinsError(true) : setRewardCoinsError(false);
        (expiryDate === "") ? setExpiryDateError(true) : setExpiryDateError(false);
    }

    const clearErrors = () => {
        setUserIdError(false);
        setCampaignNameError(false);
        setCampaignDescriptionError(false);        
        setRewardCoinsError(false);
        setExpiryDateError(false);
    }

    const changeDate = (e: any) => {
        e.preventDefault();        
        setExpiryDate(e.target.value);
    }

    return (
        <ErrorBoundary>
            <PageContainer>
                <EqualWidthContainer>
                    <div>
                        <h4 className="text-2xl">
                            Campaign being created by:<br /> 
                            <span className="font-bold">{creatorName + "(" + creatorEmail + ")"}</span>
                        </h4>
                        <br />                     
                        <form>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            {/*
                                <div>
                                    <label                         
                                        htmlFor="user_id" 
                                        className={`block mb-2 text-sm font-medium ${(userIdError ? "text-red-900": "text-gray-900")}`}>
                                            User Id
                                    </label>
                                    <input                             
                                        type="number" 
                                        id="user_id"
                                        className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(userIdError ? "border-red-500" : "")}`}
                                        placeholder="Enter user id"
                                        value={creatorId}
                                        min={1}
                                        readOnly={true}                                    
                                    />
                                </div>
                            */}
                            <div>
                                <label
                                    htmlFor="campaign_type"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Select Campaign Type
                                </label>
                                <select 
                                    id="campaign_type" 
                                    value={ campaignType } 
                                    onChange={e => setCampaignType(Number(e.target.value))}
                                >
                                    <option value={1}>User</option>
                                    <option value={2}>Community</option>
                                </select>
                            </div>
                            <div>
                                <label 
                                    htmlFor="campaign_name" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Campaign Name
                                </label>
                                <input 
                                    type="text" 
                                    id="campaign_name"
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(campaignNameError ? "border-red-500" : "")}`}
                                    placeholder="Enter campaign name"
                                    value = {campaignName}
                                    onChange = {e => setCampaignName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label 
                                    htmlFor="campaign_desc" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Campaign Description
                                </label>
                                <input 
                                    type="text" 
                                    id="campaign_desc"
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(campaignDescriptionError ? "border-red-500" : "")}`}
                                    placeholder="Enter campaign description"
                                    value = {campaignDescription}
                                    onChange = {e => setCampaignDescription(e.target.value)}
                                />
                            </div>

                            <div>
                                <label 
                                    htmlFor="refree_coin" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Joinee Coin
                                </label>
                                <input 
                                    type="number" 
                                    id="refree_coin"
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500`}
                                    min={0}
                                    placeholder="Enter refree coin"
                                    value = {refreeCoin}
                                    onChange = {e => setRefreeCoin(Number(e.target.value))}
                                />
                            </div>
                            <div>
                                <label 
                                    htmlFor="referral_coin" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Invitee Coin
                                </label>
                                <input 
                                    type="number" 
                                    id="referral_coin"
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500`}
                                    min={0}
                                    placeholder="Enter Referral Coin"
                                    value = {referralCoin}
                                    onChange = {e => setReferralCoin(Number(e.target.value))}
                                />
                            </div>

                            <div>
                                <label 
                                    htmlFor="rewards_coin" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Rewards Coin
                                </label>
                                <input 
                                    type="number" 
                                    id="rewards_coin"
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(rewardCoinsError ? "border-red-500" : "")}`}
                                    min = {0}
                                    placeholder="Enter Rewards Coin"
                                    value = {referralCoin + refreeCoin}
                                    disabled={true}
                                />
                            </div>
                            <div>
                                <label 
                                    htmlFor="expiry_date" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Expiry Date
                                </label>
                                {/* <input 
                                    type="text" 
                                    id="expiry_date"
                                    className = {(expiryDateError ? "border-red-500" : "")}
                                    placeholder="Select Expiry Date"                            
                                    value={ expiryDate }
                                    onChange = {e => setExpiryDate(e.target.value)}
                                /> */}
                                <div
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(expiryDateError ? "border-red-500" : "")}`}
                                    style={{padding: 0}}
                                >
                                    <TextInput                                                                 
                                        type="date"
                                        required={true}                                                                        
                                        onChange={(e) => changeDate(e)}                                    
                                    />
                                </div>                                
                            </div>
                            <div>
                                <button 
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={e => createCampaign(e)}
                                    disabled={inProgress}
                                >
                                    Create Campaign
                                </button>
                            </div>
                        </div>
                    </form>
                    </div> 
                </EqualWidthContainer>
            </PageContainer>            
        </ErrorBoundary>
    );
}

export default CreateCampaign;