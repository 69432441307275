import { ReactNode, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FiPaperclip } from 'react-icons/fi';
import { deleteFBbyIdAPI, getFBbyIdAPI, getPlayStoreAPI, undeleteFBbyIdAPI, updateFBStatusAPI } from "../../../api/feedback";
import { formatTime, getFeedbackTagById } from "../../../utils";
import { useAccounts } from "../../accounts/context";
import Loading from "../../Loading";
import { useFeedbackContext } from "../context";
import { FeedbackSources, FeedBackStatus, IFeedbackCardType } from "../feedbackCard";
import RepliesBox, { IReplytype } from "../reply/box";
import ReplyForm from "../reply/form";
import StatusSelect from "./components/statusSelect";

const FeedbackView = () => {
  const { state, actions } = useFeedbackContext();
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState<IFeedbackCardType>();
  const [msgs, setMsgs] = useState<Array<IReplytype>>([]);
  const navigate = useNavigate();

  const data = state.list[state.selected];
  const {
    state: { curEnv }
  } = useAccounts();

  const match = useMatch({
    path: '/dashboard/feedbacks/:item',
    end: true,
    caseSensitive: true
  });

  const fbid = useMemo(() => Number(match?.params?.item ?? 0), [match?.params.item]);

  useEffect(() => {
    const onLoad = async () => {
      setMsgs([]);
      try {
        if (fbid) {
          // const reviews = await getPlayStoreAPI();
          // console.log(reviews);
          const { result, replies } = await getFBbyIdAPI(fbid, curEnv);
          // console.log("Result: ", result);
          if (result.length > 0) {
            setViewData(result[0]);
          } else {
            setViewData({} as any);
          }
          setMsgs(replies.items);
        } else {
          setViewData({} as any);
        }
        // dispatch(AddDashCountAction(formatSQLResult(data)));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    onLoad();
  }, [fbid, curEnv]);

  const onDelete = async () => {
    try {
      if (fbid) {
        if (window.confirm('Do you really want to Archive this Feedback?')) {
          actions.DeleteListItem && actions.DeleteListItem(fbid);
          actions.setSelected && actions.setSelected(-1);
          await deleteFBbyIdAPI(curEnv, fbid);
          navigate('/dashboard/feedbacks');
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const onRecover = async () => {
    try {
      if (fbid) {
        if (window.confirm('Do you really want to Un-Archive this Feedback?')) {
          actions.DeleteListItem && actions.DeleteListItem(fbid);
          actions.setSelected && actions.setSelected(-1);
          await undeleteFBbyIdAPI(curEnv, fbid);
          navigate('/dashboard/feedbacks');
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const onStatusChange = async (status: FeedBackStatus) => {
    try {
      if (fbid) {
        actions.UpdateListItem && actions.UpdateListItem(state.selected, { status })
        // setChanged(newdata);
        await updateFBStatusAPI(fbid, status, curEnv);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const onAddReply = async (reply: IReplytype) => {
    const newReplies = [...msgs, reply];
    setMsgs(newReplies);
    if (data?.status ?? viewData?.status === 1) await onStatusChange(2);
  }

  if (!data?.fbid && !viewData?.fbid) {
    return (
      <MsgContainer>
        <Loading />
      </MsgContainer>
    );
  }
  return (
    <MsgContainer>
      {loading ? <Loading /> : (data?.fbid === 0 || viewData?.fbid === 0) ? <div className="font-bold text-center">Data Unavailable</div> : (
        <>
          {/* <UserDetails
          />                    */}
          <MsgHeader
            feedbackId={data?.fbid.toString()}
            title={data?.title ?? viewData?.title}
            src={FeedbackSources[data?.src ?? viewData?.src ?? 0]}
            userId={data?.userid}
            name={(data?.firstname ?? viewData?.firstname) + " " + (data?.lastname ?? viewData?.lastname)}
            time={data?.created?.toString().split('T')[0] ?? viewData?.created?.toString().split('T')[0] ?? ''}
            avatar={data?.thumbimage ?? viewData?.thumbimage}
            feedbackType={data?.tag}
            isAdmin={viewData?.is_admin === 1}
            isPaidMember={viewData?.is_paid_member === 1}
          >
            {data.attachment === "" ? null : <button className="mx-5" onClick={() => window.open(data.attachment, '_blank')}><FiPaperclip /></button>}
            {viewData?.deleted ? <button type="button" onClick={() => onRecover()} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Recover</button>
              : <button type="button" onClick={() => onDelete()} className="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out">Delete</button>}

            {viewData?.deleted ? null : <StatusSelect status={data?.status ?? viewData?.status ?? 1} onChange={onStatusChange} />}
          </MsgHeader>

          <MsgTitle title={data?.title ?? viewData?.title} />
          <MsgBody msg={data?.msg || viewData?.msg}></MsgBody>
          <RepliesBox replies={msgs} user={data || viewData} />
          <ReplyForm data={data || viewData} onReply={onAddReply} />
        </>)}

    </MsgContainer>
  );
}

export default FeedbackView;

export const MsgContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col w-full px-5 py-12">
      {children}
    </div>
  );
}

export const MsgHeader = (
  {
    feedbackId = '',
    title = '',
    sep = '|',
    src = '',
    userId = 0,
    name = '',
    time = '',
    avatar = '',
    feedbackType = 0,
    isAdmin = false,
    isPaidMember = false,
    children
  }:
  {
    feedbackId?: string;
    avatar?: string;
    title?: string;
    src?: string;
    userId?: number;
    name?: string;
    time?: string;
    sep?: string;
    feedbackType?: number;
    isAdmin?: boolean;
    isPaidMember?: boolean;
    children: ReactNode
  }) => {
  return (
    <>
      <div className="flex justify-between">
        <MsgUser avatar={avatar} name={name} time={time} />
        <div className="flex items-center space-x-2">
          {children}
        </div>
      </div>
      <hr className="my-5 border-t border-blue-500 "></hr>
      <h4 className="text-lg font-bold">
        {title}
        <span className="mx-3">{sep}</span>
        <span className="font-light">{src}</span>
      </h4>
      <div className="flex flex-row mt-2">
        <div>
          <b>User Id:&nbsp;</b>{userId === 0 ? 'Unknown' : userId}
        </div>
        <div className="ml-2">
          <b>Feedback Id: </b>{feedbackId}
        </div>
      </div>
      <div className="flex flex-row mt-2">
        <div>
          <b>Feedback Type: </b>{getFeedbackTagById(feedbackType)}
        </div>
        <div className="ml-2">
          <b>Is Admin:</b>&nbsp;{isAdmin ? 'Yes' : 'No'}
        </div>
        <div className="ml-2">
          <b>Is Paid Member:</b>&nbsp;{isPaidMember ? 'Yes' : 'No'}
        </div>
      </div>
    </>
  );
}

export const MsgUser = ({ avatar, name, time }: { avatar: string; name: string, time: string }) => (
  <div className="flex items-center">
    {avatar ? <img className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" src={avatar} alt="" /> : <div className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" />}
    <div className="flex justify-between w-full">
      <p className="ml-5 text-sm font-semibold"><strong className="font-bold">{name}</strong>
        <span className="mx-1">|</span>
        <span className="font-light">{time}</span>
      </p>
    </div>
  </div>
)

export const MsgTitle = ({ title }: { title: string }) => (
  <p className="mt-3 font-semibold">{title}</p>
)

export const MsgBody = ({ msg, children }: { msg?: string; children?: ReactNode }) => (
  <div>
    <p className="mb-2 text-sm">{msg}</p>
    {children}
  </div>
)

export const MsgFooter = ({ email = '' }) => (
  <div className="w-full mt-12">
    <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">{email ? `Reply Message to ${email}` : 'No Email Provided'}</label>
    <textarea disabled={true} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md h-36 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"></textarea>
    <div>
      <button disabled={true} type="button" className="float-right mt-3 inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out">Send</button>
    </div>
  </div>
);

const UserDetails = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full"></div>
        <div className="justify-between">Chris Evans</div>
      </div>
    </div>
  );
}
