import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { isNumber } from "../../utils";
import clsx from 'clsx';
import { FiPaperclip } from 'react-icons/fi';

export const StatusArray = [{ title: 'Unknown', col: 'green' }, { title: 'Unattended', col: 'green' }, { title: 'In Review', col: 'blue' }, { title: 'Closed', col: 'yellow' }, { title: 'Trashed', col: 'red' }]

export enum FeedbackSources {
  Unknown,
  Inapp = 1,
  Playstore,
  Appstore,
}
export enum FeedBackStatus {
  Unattended = 1,
  'In Review',
  Closed,
  Trashed
}

export enum FeedbackTag {
  Unknown,
  Suggestions = 1,
  Grievances,
  Help,
  Suspended,
  CommunityExit
}
export interface IFeedbackCardType {
  attachment?: string;
  email?: string;
  fbid: number | string;
  userid: number;
  thumbimage?: string;
  firstname?: string;
  lastname?: string;
  src?: number;
  created?: string | number;
  title: string;
  msg: string;
  label?: string;
  tag?: number;
  status?: FeedBackStatus;
  rating?: number;
  deleted?: 0 | 1;
  is_admin?: 0 | 1;
  is_paid_member?: 0 | 1;
}
const FeedbackCard = ({ data, index, selected, onSelect }: { data: IFeedbackCardType; index: number, selected: boolean; onSelect: () => void }) => {
  return (
    <div className="flex mx-3 mt-2" onClick={() => onSelect()}>
      <Link to={data.fbid.toString()} className="w-full">
        <CardContainer selected={selected}>
          <CardHeader name={data.firstname + " " + data.lastname} src={FeedbackSources[data?.src ?? 0]} time={data?.created ?? ''} avatar={data.thumbimage} />
          <CardTitle title={data.title} />
          <CardMsg msg={data.msg} />
          <CardFooter tag={FeedbackTag[data?.tag ?? 0] ?? ''}>
            <FeedbackStatusItem status={data?.status ?? 1} />
          </CardFooter>
          <CardRatings ratings={data?.rating ?? 0} link={data?.attachment ?? ""} />

        </CardContainer>
      </Link>
    </div>
  );
}

export const FeedbackStatusItem = ({ status }: { status: number }) => {
  return <FeedbackStatusItemElement item={StatusArray[status]} />
}

const CardRatings = ({ratings, link} : {ratings: number; link: string}) => {
  return (
    <div className="mt-2 text-sm flex flex-row justify-between">
      <div>
        <span className="font-semibold">Ratings:&nbsp;</span>
        <span>{ratings}</span>
      </div>
      {link === "" ? null: <button onClick={() => window.open(link, '_blank')}><FiPaperclip /></button>}
    </div>
  );
}

export const FeedbackStatusItemElement = ({ item }: { item: typeof StatusArray[0] }) => {
  return (<div className="flex items-center space-x-3">
    <div className={`h-2 w-2 rounded-full`} style={{ backgroundColor: item.col }}></div>
    <p className="text-xs font-light">{item.title}</p>
  </div>)
}

export const CardContainer = ({ children, selected = false }: { selected?: boolean; children: ReactNode }) => {
  return (
    <div className={clsx(["w-full p-5 bg-white rounded shadow-lg hover:bg-blue-100 ease-in-out duration-300", { "bg-green-100": selected }])}>
      <div className="w-full">
        {children}
      </div></div>
  );
}

export const CardHeader = ({ name, src, time, avatar }: { name: string; src: string; time: string| number, avatar?: string }) => {
  return (<div className="flex items-center">
    {avatar ? <img className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" src={avatar} alt="" /> : <div className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" />}
    <div className="flex justify-between w-full">
      <p className="ml-5 text-sm font-semibold"><strong className="font-bold">{name}</strong>
        <span className="mx-1">|</span>
        <span className="font-light">{src}</span>
      </p>
      <p className="ml-1 text-xs text-gray-500">{isNumber(time.toString()) ? time : time.toString().split('T')[0]}</p>
    </div>
  </div>)
}

export const CardTitle = ({ title }: { title: string }) => {
  return (
    <p className="mt-3 font-semibold">{title}</p>
  );
}

export const CardMsg = ({ msg }: { msg: string }) => {
  return (
    <p className="mb-2 text-sm">{msg}</p>
  );
}

export const CardFooter = ({ tag, children }: { tag: string; children: ReactNode }) => {
  return (<div className="flex items-center justify-between mt-3">
    <p className="p-2 text-xs font-light border-2 border-gray-400 rounded">{tag}</p>
    {children}
  </div>)
}
export default FeedbackCard;
