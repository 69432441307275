const config = {
  s3: {
    REGION: '',
    BUCKET: '',
  },
  apiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://v1-adminapi.one.investmates.io',
  },
  devapiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://dev-adminapi.one.investmates.io',
  },
  stageapiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://stage-adminapi.one.investmates.io',
  },
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_WkH4eAeXZ',
    APP_CLIENT_ID: '5o961qhl8t2tgfutrddteioc4t',
    IDENTITY_POOL_ID: 'ap-south-1:230b8473-0061-4fca-8b55-b3f534b2f7c3',
    // oauth: {
    //   domain: 'accounts.investmates.io',
    //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: window.location.origin + '?signedin=true',
    //   redirectSignOut: window.location.origin + '/',
    //   responseType: 'code',
    // },
  },
}

export default config;
