import { useEffect, useState } from "react";
import { getConversionListByCampaignId } from "../../api/rewards";
import { IConversionListElement } from "../../utils";
import { useAccounts } from "../accounts/context";
import { CommunityTableContainer, TableHeaders } from "../communities/engaging";
import Loading from "../Loading";

const ConversionList = ({campaignId} : {campaignId: number}) => {
    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState<Array<IConversionListElement>>([]);
    const [referrersList, setReferrersList] = useState<Array<string>>([]);
    const [referrerFilter, setReferrerFilter] = useState("All");
    // const [filterType, setFilterType] = useState("");
    const {
        state: { curEnv }
    } = useAccounts();

    useEffect(() => {
        async function getList() {            
            const campaignList = await getConversionListByCampaignId(curEnv ,campaignId);
            setUsersList([]);
            // setReferrersList([]);            
            campaignList.forEach((element: any) => {
                const user = {} as IConversionListElement;
                user.campaignId = element["campaignid"];
                user.created = element["created"].split("T")[0];
                user.Id = element["id"];
                user.joinedUser = element["joined_user"];
                user.joinedUserId = element["userid"];
                user.referredBy = element["referred_by"];
                user.referredUserId = element["referaluserid"];
                setUsersList(oldData => [...oldData, user]);
                if (referrersList.indexOf(user.referredBy) == -1) {
                    const referrer = user.referredBy;
                    referrersList.push(referrer);
                    // setReferrersList(oldData => [...oldData, referrer]);
                }
            });                       
        }
        getList();
    }, [curEnv]);    

    const fillTable = (filter: string) => {
        if (filter === "All" || filter === "") {
            return usersList.map((user, index) => {
                return <tbody key = {index + '' + user.Id} >
                    <tr className="border-b">
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.joinedUser}
                        </td>
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.referredBy}
                        </td>
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.created}
                        </td>
                    </tr>
                </tbody>
            });
        } else {
            return usersList.map((user, index) => {
                return user.referredBy === filter && <tbody key = {index + '' + user.Id} >
                    <tr className="border-b">
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.joinedUser}
                        </td>
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.referredBy}
                        </td>
                        <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                            {user.created}
                        </td>
                    </tr>
                </tbody>
            });
        }      
    }
    
    const filterByReferrer = (e: any) => {
        e.preventDefault();
        setReferrerFilter(e.target.value)        
    }

    return (
        <div>
            <h2>Filter</h2>
            <p>
                <span>
                    By Referrers:&nbsp;
                    <select onChange={e => filterByReferrer(e)}>
                        <option value="All">All</option>
                        {
                            referrersList.map(referrer => {
                                return <option value={referrer}>{referrer}</option>
                            })
                        }
                    </select>
                </span>
            </p>
            {
                loading 
                ? <Loading />
                : 
                <div className="p-2 border-2 rounded">
                    <h4 className="font-bold text-center">User Conversion List</h4>
                    <CommunityTableContainer>
                        <TableHeaders cols={[
                            "User",
                            "Referred By",
                            "Joined On"
                        ]} />
                        {
                            fillTable(referrerFilter)
                        }
                    </CommunityTableContainer> 
                </div>                
            }
        </div>
    );
}

export default ConversionList;